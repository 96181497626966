<template>
    
</template>
<script> 
export default  {
  mounted(){
    this.$store.dispatch('auth/logout').then(()=>{ 
      this.$router.push(
        this.$route.query.redirectFrom || { path: "/login" }
      );
    }).cach(() => {
        this.$router.push(
            this.$route.query.redirectFrom || { path: "/login" }
        ); 
    })
  }
}
</script>
