<template>
    <div class="row">
      <PageHeader :title='$t("managers.edit_title")'  />
      <div class="col-12">
        <div class="card p-4">
          <b-alert v-if="haveError" variant="danger" show dismissible class="alert-border alert-border-danger">
            <i class="uil uil-exclamation-octagon font-size-16 text-danger me-2"></i>
            {{ errorMessage }}
          </b-alert>
          <b-alert v-if="haveSuccess" variant="success" show dismissible class="alert-border alert-border-success">
            <i class="uil uil-check font-size-16 text-success me-2"></i>
            {{successMessage}}
          </b-alert>
          <div class="col-lg-12 mt-4">
            <b-form-input :placeholder='$t("select_options.name")' v-model="edit_manager.name"></b-form-input>
          </div>
          <div class="col-lg-12 mt-4">
            <b-form-input :placeholder='$t("select_options.surname")' v-model="edit_manager.surname"></b-form-input>
          </div>
          <div class="col-lg-12 mt-4">
            <b-form-input :placeholder='$t("select_options.email")' v-model="edit_manager.email"></b-form-input>
          </div>
          <div class="col-lg-12 mt-4">
            <b-form-input :placeholder='$t("select_options.password")' type="password" v-model="edit_manager.password"></b-form-input>
          </div>
          <div class="col-lg-12 mt-4">
            <select v-model="status" class="form-select">
              <option value="select_status">{{ $t("select_options.select_status") }}</option>
              <option value="0">{{ $t("select_options.deactivate") }}</option>
              <option value="1">acti{{ $t("select_options.active") }}ve</option>
            </select>
          </div>
          <div class="w-100">
            <b-button variant="primary" size="md"  class="float-right col-12 mt-4"  @click="edit">{{ $t("select_options.update") }}</b-button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header";
export default {
  components: { PageHeader },
  data(){
    return{
      status:'select_status',
      st:'',
      edit_manager:{
        id:'',
        name:'',
        surname:'',
        email:'',
        password:'',
      },
      haveSuccess:false,
      haveError:false,
      successMessage:'',
      errorMessage:'',
      title: "edit_managers",
    }
  },
  methods: {
    edit(){
      const data = {
        id:this.edit_manager.id,
        name:this.edit_manager.name,
        surname:this.edit_manager.surname,
        email:this.edit_manager.email,
        password:this.edit_manager.password,
        status:this.status === 'select_status' ? '' : this.status
      }
      this.$store.dispatch('managers/edit',data).then(() =>{
        this.haveError = false
        this.haveSuccess = true
        this.errorMessage = ''
        sessionStorage.setItem('sessionMessage',this.$store.state.managers.successMessage.data.message)
        this.$router.push({path: "/managers/list"});
      }).catch(() => {
        this.haveError = true
        this.haveSuccess = false
        this.successMessage = ''
        this.errorMessage = this.$store.state.managers.errorMessage
      })
    },
    getData(){
      this.$store.dispatch('managers/detail', this.$route.params.id).then(() =>{
        this.edit_manager.id = this.$store.state.managers.data.data.id
        this.edit_manager.username = this.$store.state.managers.data.data.username
        this.edit_manager.name = this.$store.state.managers.data.data.name
        this.edit_manager.surname = this.$store.state.managers.data.data.surname
        this.edit_manager.email = this.$store.state.managers.data.data.email
        this.edit_manager.status = this.$store.state.managers.data.data.status
      }).catch(() => {
        this.$router.push('/404')
      })
    }

  },

  mounted(){
    this.getData()
  }
}
</script>