<template>
  <div>
    <PageHeader :title="$t('menu-items.dashboard')"/>
    <Stat :dashboard="dashboard"/>
    <div class="row">
      <SalesAnalytics :dashboard="dashboard"/>
<!--       <div class="col-xl-4">-->
<!--            <div class="card bg-primary">-->
<!--                <div class="card-body">-->
<!--                    <div class="row align-items-center">-->
<!--                        <div class="col-sm-8">-->
<!--                            <p class="text-white font-size-18">-->
<!--                                {{$t('dashboard.second_line.poster.text')}}-->
<!--                                <b>{{$t('dashboard.second_line.poster.text2')}}</b> {{$t('dashboard.second_line.poster.text3')}}-->
<!--                                <i class="mdi mdi-arrow-right"></i>-->
<!--                            </p>-->
<!--                            <div class="mt-4">-->
<!--                                <a href="javascript: void(0);" class="btn btn-success waves-effect waves-light">{{$t('dashboard.second_line.start.text')}}</a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-4">-->
<!--                            <div class="mt-4 mt-sm-0">-->
<!--                                <img src="@/assets/images/setup-analytics-amico.svg" class="img-fluid" alt />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; end card-body&ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash; end card&ndash;&gt;-->
<!--            <SellingProduct />-->
<!--        </div>-->
    </div>
    <div class="row">
      <TopUsers :users="users"/>
      <Activity :activities="activities"/>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/page-header";
import Stat from "@/components/widgets/stat";
import SalesAnalytics from "./sales-analytics";
import SellingProduct from './selling-product';
import TopUsers from './top-users';
import Activity from './activity';
export default {
  components: { PageHeader, Stat, SalesAnalytics, SellingProduct, TopUsers, Activity },
  data(){
    return {
      activities:[],
      users:[],
      dashboard:[],
    };
  },
  mounted(){
    this.$store.dispatch("dashboard/list",'').then(()=>{
      this.users = this.$store.state.dashboard.data.data.customers
      this.activities = this.$store.state.dashboard.data.data.activities
      this.dashboard = this.$store.state.dashboard.data.data.dashboard
    })
  }
};
</script>