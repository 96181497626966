<template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ $t("affiliates.detail_title") }}</h4>
            <b-alert :show="dismissCountDown" v-if="haveSessionMessage" dismissible :variant="variant" @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">
              {{ sessionMessage }}
            </b-alert>
            <div class="table-responsive mb-0">
              <b-table striped hover :items="tableData" :fields="fields">
                <template v-slot:cell(status)="data">
                  <div class="form-check form-switch form-switch-lg" dir="ltr">
                    <input @change="changeStatus(data.item.id)" type="checkbox" class="form-check-input" id="customSwitchsizelg" :checked="data.item.status"/>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  {{data.item.name}} {{data.item.surname}}
                </template>
                <template v-slot:cell(edit)="data">
                  <ul class="list-inline button-items mb-0">
                    <li class="list-inline-item">
                      <div class="button-items">
                        <router-link :to="`/customers/edit/${data.item.id}`"
                                     href="javascript:void(0);" class="btn btn-warning">
                          <i class="bx bx-edit-alt "></i>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </template>
                <template v-slot:cell(detail)="data">
                  <ul class="list-inline button-items mb-0">
                    <li class="list-inline-item">
                      <div class="button-items">
                        <router-link :to="`/customers/detail/${data.item.id}`"
                                     href="javascript:void(0);" class="btn btn-primary">
                          <i class="bx bxs-user-detail "></i>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </template>
                <template v-slot:cell(delete)="data">
                  <ul class="list-inline button-items mb-0">
                    <li class="list-inline-item">
                      <div class="button-items">
                        <b-button variant="danger" @click="del(data.item.id)"><i class="far fa-trash-alt"></i></b-button>
                      </div>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="onFiltered"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
export default {
  components: { PageHeader},
  data() {
    return {
      variant:"success",
      haveSessionMessage:false,
      sessionMessage:'',
      dismissSecs: 5,
      dismissCountDown:0,
      affiliateData:[],
      edit_affiliate:false,
      haveSuccess:false,
      add_affiliate:true,
      options:{
        page:1,
        groupBy:[],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false ,
        sortBy: [],
        sortDesc: [],
      },
      tableData: [],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 0,
      pageOptions: [10, 20, 30, 50, 100],
      start_date:'',
      end_date:'',
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      total:0,
      fields: [
        {key: "id", sortable: true},
        {key: "user_id", sortable: true},
        "name",
        {key: "phone", sortable: true},
        {key: "email", sortable: true},
        {key: "status", sortable: true},
        {key: "created_at", sortable: true},
        "edit",
        "detail",
        "delete"
      ],
      id:'',
      name:'',
      status:100,
    };
  },
  mounted() {
    this.start_date = this.getLastDay(1, ' 00:00:00')
    this.end_date   = this.getLastDay(0, ' 23:59:59')
    this.getData()
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    changeStatus(id){
      this.$store.dispatch("customers/status", id).then(()=>{
        this.haveSessionMessage = true
        this.variant='success'
        this.sessionMessage = this.$store.state.customers.successMessage.data.message
        this.showAlert()
      }).catch(()=>{
        this.haveSessionMessage = true
        this.variant='danger'
        this.sessionMessage = this.$store.state.customers.errorMessage
        this.showAlert()
      });
    },
    del(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons.fire({
        title: this.$t("alerts.are_you_sure"),
        text: this.$t("alerts.you_want_to_delete_this"),
        icon: "warning",
        confirmButtonText: this.$t("alerts.yes"),
        cancelButtonText: this.$t("alerts.no"),
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('affiliates/delete',id).then(()=>{
            swalWithBootstrapButtons.fire("deleted", this.$store.state.affiliates.successMessage.data.messages, "success");
            this.getData()
          }).catch(()=>{
            swalWithBootstrapButtons.fire("warning", this.$t("alerts.data_not_found"), "warning");
          })
        }
      });
    },
    getData(){
      this.tableData = []
      // let params = {
      //   page:this.currentPage,
      //   per_page:this.perPage,
      //   id:this.id,
      //   name:this.name,
      //   status:this.status=='100'?'':this.status
      // }
      // let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch("customers/affiliate",this.$route.params.id).then(()=>{
        this.tableData =  this.$store.state.customers.data.data.data
        this.totalRows   = this.$store.state.affiliates.data.data.total;
        this.perPage     = this.$store.state.affiliates.data.data.per_page
        this.currentPage = this.$store.state.affiliates.data.data.current_page
      }).catch(()=>{
      //  this.$router.push('/404')
      });
    },
    onFiltered(filteredItems) {
      this.currentPage = filteredItems;
      this.getData()
    },
  }
};
</script>