
import Vue from 'vue'
import Axios from 'axios'

export const state = {
    currentUser:'',
    errorMessage:'',
    token:'',
    isLoggedIn:true,
}

export const mutations = {
    logout(state){
        state.currentUser = ''
        state.token = '' 
    },
    auth_error(state,data){ 
        state.errorMessage = data
    },
    update_profile(state){
        state.currentUser = JSON.parse(localStorage.getItem('auth.currentUser')) || ''
    },
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)  
    },
    auth_success(state, data){ 
        state.token = data 
    },
}

export const getters = { 
    loggedIn(state) { 
        return !!state.isLoggedIn
    },
}

export const actions = { 

    init({ state, dispatch }) {
        dispatch('validate')
    },

    refresh({commit}){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/account/refresh', method:"POST"}).then(resp => {
                const token = resp.data.data.access_token
                localStorage.setItem('access_token', token)
                Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+resp.data.data.access_token
                localStorage.setItem('loginTime', Date.now().toString())
                commit('auth_success',token)
                resolve(resp)
            }).catch(err => {
                commit('auth_error')
                localStorage.removeItem('access_token')
                reject(err)
            })
        })
    },

    profile({ commit }  ){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/account', method:"GET"}).then(resp => {
                commit('SET_CURRENT_USER', resp.data.data) 
                localStorage.setItem('auth.currentUser',JSON.stringify(resp.data.data)),
                resolve(resp)  
            }).catch(err => { 
                for(let i in err.response.data){
                    commit('auth_error',err.response.data[i][0])
                }
                localStorage.removeItem('access_token')
                reject(err)
            }) 
        }) 
    },

    logIn({ commit, dispatch, getters }, { username, password } = {}) {  
        // if (getters.loggedIn) return dispatch('validate')
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/login', data: {username, password},method:"POST"}).then(resp => { 
                const token = resp.data.data.access_token
                localStorage.setItem('access_token', token) 
                Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+resp.data.data.access_token
                localStorage.setItem('loginTime', Date.now().toString())  
                commit('auth_success',token) 
                resolve(resp)  
            }).catch(err => { 
                if(err.response.data.data){
                    commit('auth_error',err.response.data.data.message)
                }else {
                    for(let i in err.response.data){
                        commit('auth_error',err.response.data[i][0])
                    }
                } 
                localStorage.removeItem('access_token')
                reject(err)
            })
        }) 
    },
    updateProfile({commit}, user){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/account', data: user,method:"GET"}).then(resp => {
                localStorage.removeItem('auth.currentUser')
                localStorage.setItem('auth.currentUser', JSON.stringify(resp.data.data))
                commit('SET_CURRENT_USER', resp.data.data)
                resolve(resp)
            }).catch(err => {
                commit('auth_error')
                localStorage.removeItem('access_token')
                reject(err)
            })
        })
    },

    logout({commit}){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/account/logout', method:"POST"}).then(resp => {
                commit('SET_CURRENT_USER', null)
                localStorage.removeItem('access_token');  
                localStorage.removeItem('auth.currentUser');
                localStorage.removeItem('loginTime');
                delete Axios.defaults.headers.common['Authorization']
                commit('logout') 
            }).catch(err => {
                reject(err)
            })
        })
      },
 
    validate({ commit, state }) {
        // if (!state.currentUser) return Promise.resolve(null)
        // let user = state.currentUser
        // commit('SET_CURRENT_USER', user)
        // return user;
    },
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}