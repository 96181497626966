<template>

    <div class="row">
      <PageHeader :title='$t("customers.edit_title")' />
      <div class="col-12">
        <div class="card">
          <div class="card-body p-4">
            <b-alert v-if="haveError" variant="danger" show dismissible class="alert-border alert-border-danger">
              <i class="uil uil-exclamation-octagon font-size-16 text-danger me-2"></i>
              {{ errorMessage }}
            </b-alert>
            <b-alert v-if="haveSuccess" variant="success" show dismissible class="alert-border alert-border-success">
              <i class="uil uil-check font-size-16 text-success me-2"></i>
              {{successMessage}}
            </b-alert>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.user_id")'  v-model="edit_customers.user_id"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.name")' v-model="edit_customers.name"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("cuselect_options.surname")' v-model="edit_customers.surname"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.phone")' v-model="edit_customers.phone"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.email")' v-model="edit_customers.email"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <multiselect v-model="edit_customers.affiliate_id"   track-by="full_name"
                           label="full_name" :placeholder='$t("select_options.select_affiliates")' :options="affiliates_list" :searchable="true" :allow-empty="false">
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong>{{ option.name }}</strong> selected<strong>  {{ option.language }}</strong></template>
              </multiselect>
            </div>
            <div class="w-100">
              <b-button variant="primary" size="md" class="float-right col-12 mt-4" @click="edit">{{ $t("select_options.edit_btn") }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
export default {
  components: {PageHeader,Multiselect },
  data() {
    return {
      edit_customers:{
        id:'',
        user_id:'',
        name:'',
        surname:'',
        phone:'',
        email:'',
        affiliate_id:"",
      },
      haveSuccess:false,
      haveError:false,
      successMessage:'',
      errorMessage:'',
      affiliates_list: [],
    };
  },
  methods:{
    edit(){
      const data = {
        id:this.edit_customers.id,
        name:this.edit_customers.name,
        user_id:this.edit_customers.user_id,
        surname:this.edit_customers.surname,
        phone:this.edit_customers.phone,
        email:this.edit_customers.email,
        affiliate_id:this.edit_customers.affiliate_id ? this.edit_customers.affiliate_id.id : '',
      }
      this.$store.dispatch('customers/edit',data).then(() =>{
        this.haveError = false
        this.haveSuccess = true
        this.errorMessage = ''
        sessionStorage.setItem('sessionMessage',this.$store.state.customers.successMessage.data.message)
        this.$router.push({path: "/customers/list"});
      }).catch(() => {
        this.haveError = true
        this.haveSuccess = false
        this.successMessage = ''
        this.errorMessage = this.$store.state.customers.errorMessage
      })
    },
    getData() {
      this.$store.dispatch('customers/detail', this.$route.params.id).then(() => {
        this.edit_customers.id = this.$store.state.customers.data.data.id
        this.edit_customers.user_id = this.$store.state.customers.data.data.user_id
        this.edit_customers.name = this.$store.state.customers.data.data.name
        this.edit_customers.surname = this.$store.state.customers.data.data.surname
        this.edit_customers.phone = this.$store.state.customers.data.data.phone
        this.edit_customers.email = this.$store.state.customers.data.data.email
        this.edit_customers.affiliate_id = this.$store.state.customers.data.data.affiliate_id
      })
    }
  },
  mounted(){
    let params = {per_page:100}
    let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
    this.$store.dispatch("affiliates/list",queryString).then(()=>{
      let affiliates = this.$store.state.affiliates.data.data.data
      for(let i in affiliates){
        this.affiliates_list.push({
          id : affiliates[i].id,
          percent : affiliates[i].percent,
          status : affiliates[i].status,
          full_name : affiliates[i].name +" "+ affiliates[i].surname,
          username : affiliates[i].username,
          created_at : affiliates[i].created_at,
        })
      }
    }).catch(()=>{
      this.$router.push('/404')
    });
    this.getData()
  }
};
</script>