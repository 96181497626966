import Axios from 'axios'

export const state = {
    errorMessage:'',
    successMessage:'',
    data:[],
}
export const mutations = {
    error_data(state,data){
        state.errorMessage = data
    },
    success_data(state,data){
        state.successMessage = data
    },
    response_data(state,data){
        state.data = data
    }
}

export const getters = {

}

export const actions = {
    list({ commit }, querystring){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/customers?'+querystring, method:"GET"}).then(resp => {
                commit('response_data',resp.data)
                resolve(resp)
            }).catch(err => {
                for(let i in err.response.data){
                    commit('error_data',err.response.data[i][0])
                }
                reject(err)
            })
        })
    },
    add({ commit }, data){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/customers', data:data, method:"POST"}).then(resp => {
                commit('success_data',resp.data)
                resolve(resp)
            }).catch((err) => {
                if(err.response.status === 422){
                    for(let i in err.response.data){
                        commit('error_data',err.response.data[i][0])
                    }
                }else if(err.response.status === 421){
                    commit('error_data',err.response.data.data.message)
                }
                reject(err)
            })
        })
    },
    edit({commit}, data) {
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT + '/customers/'+data.id, data:data, method:"PUT"}).then(resp => {
                commit('success_data',resp.data)
                resolve(resp)
            }).catch(err => {
                if(err.response.status === 422){
                    for(let i in err.response.data){
                        commit('error_data',err.response.data[i][0])
                    }
                }else if(err.response.status === 421){
                    commit('error_data',err.response.data.data.message)
                }
                reject(err)
            })
        })
    },
    detail({ commit }, id){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/customers/'+id, method:"GET"}).then(resp => {
                commit('response_data',resp.data)
                resolve(resp)
            }).catch(err => {
                for(let i in err.response.data){
                    commit('error_data',err.response.data[i][0])
                }
                reject(err)
            })
        })
    },
    delete({ commit }, id){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/customers/'+id, method:"DELETE"}).then(resp => {
                commit('success_data',resp.data)
                resolve(resp)
            }).catch(err => {
                for(let i in err.response.data){
                    commit('error_data',err.response.data[i][0])
                }
                reject(err)
            })
        })
    },
    status({ commit }, id){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/customers/status/'+id, method:"PUT"}).then(resp => {
                commit('success_data',resp.data)
                resolve(resp)
            }).catch(err => {
                for(let i in err.response.data){
                    commit('error_data',err.response.data[i][0])
                }
                reject(err)
            })
        })
    },
    affiliate({ commit }, id){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/customers?affiliate_id='+id, method:"GET"}).then(resp => {
                commit('response_data',resp.data)
                resolve(resp)
            }).catch(err => {
                for(let i in err.response.data){
                    commit('error_data',err.response.data[i][0])
                }
                reject(err)
            })
        })
    },

}