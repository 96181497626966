<template>
    <div class="row">
      <PageHeader :title="title"  />
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                       :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"></b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="onFiltered"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header"; 
export default {
  components: {  PageHeader },
  data() {
    return {
      tableData: [],
      title: "Session history",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [ 
        {key: "id", sortable: true,},
        {key: "ip", sortable: true,},
        {key: "user_agent", sortable: true,},
        {key: "created_at", sortable: true,},
      ],
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      this.tableData = []
      let params = {
        page:this.currentPage,
        per_page: this.perPage === 'select_per_page' ? 10 : this.perPage,
      }
      let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch("account/session_history",queryString).then((resp)=>{
        this.tableData = this.$store.state.account.data.data
        this.totalRows = this.$store.state.account.data.data.total;
        this.perPage     = this.$store.state.customers.data.data.per_page
        this.currentPage = this.$store.state.customers.data.data.current_page
      }).catch((err)=>{
        console.log(err)
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.getData()
    },
  },
};
</script>