<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img src="@/assets/images/logo-dark.png" height="22" class="logo logo-dark"/>
                <img src="@/assets/images/logo-light.png" height="30" class="logo logo-light" />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to Eclipse.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>
                  <div v-if="notification.message" :class="'alert ' + notification.type">
                    {{ notification.message }}
                  </div>
                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group id="input-group-1" class="mb-3" label="Username" label-for="input-1">
                      <b-form-input id="input-1" v-model="username" type="text" placeholder="Enter username"
                      :class="{ 'is-invalid': submitted && $v.username.$error }"></b-form-input>
                      <div v-if="submitted && $v.username.$error" class="invalid-feedback">
                        <span v-if="!$v.username.required">Username is required.</span> 
                      </div>
                    </b-form-group> 
                    <b-form-group id="input-group-2" class="mb-3"> 
                      <label for="input-2">Password</label>
                      <b-form-input id="input-2" v-model="password" type="password" placeholder="Enter password"
                        :class="{ 'is-invalid': submitted && $v.password.$error, }" ></b-form-input>
                      <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                        Password is required.
                      </div>
                    </b-form-group> 
                    <div class="mt-3 text-end">
                      <b-button type="submit" variant="primary" class="w-sm">Log In</b-button>
                    </div> 
                  </b-form>
                </div> 
              </div> 
            </div> 
          </div> 
        </div> 
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators"; 
export default {
 
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  validations: {
    username: {
      required, 
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  methods: { 
    tryToLogIn() {
      this.submitted = true; 
      this.$v.$touch(); 
      if (this.$v.$invalid) {
        return;
      } else { 
          this.tryingToLogIn = true; 
          this.authError = null;
          this.$store.dispatch("auth/logIn", { username: this.username,password: this.password}).then((token) => {
            this.tryingToLogIn = false;
            this.isAuthError = false; 
            this.$store.dispatch('auth/profile').then(() => {

              let refresh = setInterval(()=>{
                this.$store.dispatch('auth/refresh').catch(err=>{
                  clearInterval(refresh)
                })
              },process.env.VUE_APP_REFRESH_TIME)
              this.$router.push(
                this.$route.query.redirectFrom || { path: "/" }
              );
            }) .catch((err) => {
              this.isAuthError = true; 
            });
          }).catch(( ) => { 
            this.tryingToLogIn = false;
            this.authError = this.$store.state.auth.errorMessage;
            this.isAuthError = true;
          })
      }
    },
  },
};
</script>
<style lang="scss" module></style>
