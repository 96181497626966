<template>
    <div class="row">
      <PageHeader :title='$t("managers.add_title")'  />
      <div class="col-12">
        <div class="card">
          <div class="card-body p-4">
            <b-alert v-if="haveError" variant="danger" show dismissible class="alert-border alert-border-danger">
              <i class="uil uil-exclamation-octagon font-size-16 text-danger me-2"></i>
              {{ errorMessage }}
            </b-alert>
            <b-alert v-if="haveSuccess" variant="success" show dismissible class="alert-border alert-border-success">
              <i class="uil uil-check font-size-16 text-success me-2"></i>
              {{successMessage}}
            </b-alert>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.username")' v-model="add_manager.username"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.name")' v-model="add_manager.name"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.surname")' v-model="add_manager.surname"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.email")' v-model="add_manager.email"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.password")' type="password" v-model="add_manager.password"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.confirm-password")' type="password" v-model="add_manager.password_confirmation"></b-form-input>
            </div>
            <div class="w-100">
              <b-button variant="primary" size="md"  class="float-right col-12 mt-4"  @click="add">{{ $t("select_options.create") }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header";
export default {
  components: { PageHeader },
  data() {
    return {
      add_manager:{
        username:'',
        name:'',
        email:'',
        surname:'',
        password:'',
        password_confirmation:'',
      },
      haveSuccess:false,
      haveError:false,
      successMessage:'',
      errorMessage:'',
    };
  },
  methods:{
    add(){
      const params = {
        username:this.add_manager.username,
        name:this.add_manager.name,
        email:this.add_manager.email,
        surname:this.add_manager.surname,
        password_confirmation:this.add_manager.password_confirmation,
        password:this.add_manager.password
      }
      let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch('managers/add',queryString).then(() =>{
        this.haveError = false
        this.haveSuccess = true
        this.errorMessage = ''
        sessionStorage.setItem('sessionMessage',this.$store.state.managers.successMessage.data.message)
        this.$router.push({path: "/managers/list"});
      }).catch(() => {
        this.haveError = true
        this.haveSuccess = false
        this.successMessage = ''
        this.errorMessage = this.$store.state.managers.errorMessage
      })
    },
  }
};
</script>