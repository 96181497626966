<template>
    <div class="row">
      <PageHeader :title="title"  />
      <div class="col-12">
        <div class="card">
          <div class="card-body p-4">
            <b-alert v-if="haveError" variant="danger" show dismissible class="alert-border alert-border-danger">
              <i class="uil uil-exclamation-octagon font-size-16 text-danger me-2"></i>
              {{ errorMessage }}
            </b-alert>
            <b-alert v-if="haveSuccess" variant="success" show dismissible class="alert-border alert-border-success">
              <i class="uil uil-check font-size-16 text-success me-2"></i>
              {{successMessage}}
            </b-alert>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.user_id")'  v-model="add_customers.user_id"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.name")' v-model="add_customers.name"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.surname")' v-model="add_customers.surname"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.phone")' v-model="add_customers.phone"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.email")' v-model="add_customers.email"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
                <multiselect v-model="add_customers.affiliate_id"   track-by="full_name"
                 label="full_name" :placeholder='$t("select_options.select_affiliates")' :options="affiliates_list" :searchable="true" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong> selected<strong>  {{ option.language }}</strong></template>
                </multiselect>
            </div>
            <div class="w-100">
              <b-button variant="primary" size="md" class="float-right col-12 mt-4" @click="add">{{ $t("select_options.create") }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
export default {
  components: {PageHeader, Multiselect },
  data() {
    return {
      add_customers:{
        user_id:'',
        name:'',
        surname:'',
        phone:'',
        email:'',
        affiliate_id:"",
      },
      haveSuccess:false,
      haveError:false,
      successMessage:'',
      errorMessage:'',
      title: this.$t("customers.add_title"),
      affiliates_list: [],
    };
  },
  methods:{
    add(){
      const params = {
        user_id:this.add_customers.user_id,
        name:this.add_customers.name,
        surname:this.add_customers.surname,
        phone:this.add_customers.phone,
        email:this.add_customers.email,
        affiliate_id:this.add_customers.affiliate_id.id,
      }
      let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch('customers/add',queryString).then(() =>{
        this.haveError = false
        this.haveSuccess = true
        this.errorMessage = ''
        sessionStorage.setItem('sessionMessage',this.$store.state.customers.successMessage.data.message)
        this.$router.push({path: "/customers/list"});
      }).catch(() => {
        this.haveError = true
        this.haveSuccess = false
        this.successMessage = ''
        this.errorMessage = this.$store.state.customers.errorMessage
      })
    },

  },
  mounted(){
    this.$store.dispatch("affiliates/list").then(()=>{
      let affiliates = this.$store.state.affiliates.data.data.data
      for(let i in affiliates){
        this.affiliates_list.push({
          id : affiliates[i].id,
          percent : affiliates[i].percent,
          status : affiliates[i].status,
          full_name : affiliates[i].name +" "+ affiliates[i].surname,
          username : affiliates[i].username,
          created_at : affiliates[i].created_at,
        })
      }
    }).catch(()=>{
      this.$router.push('/404')
    });
  }
};
</script>