<template>
    <div class="row">
      <PageHeader :title='$t("payment_methods.edit_title")'  />
      <div class="col-12">
        <div class="card p-4">
          <b-alert v-if="haveError" variant="danger" show dismissible class="alert-border alert-border-danger">
            <i class="uil uil-exclamation-octagon font-size-16 text-danger me-2"></i>
            {{ errorMessage }}
          </b-alert>
          <b-alert v-if="haveSuccess" variant="success" show dismissible class="alert-border alert-border-success">
            <i class="uil uil-check font-size-16 text-success me-2"></i>
            {{successMessage}}
          </b-alert>
          <div class="col-lg-12 mt-4">
            <b-form-input :placeholder='$t("select_options.name")' v-model="payments.name"></b-form-input>
          </div>
          <div class="w-100">
            <b-button variant="primary" size="md"  class="float-right col-12 mt-4"  @click="edit">{{ $t("select_options.update") }}</b-button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
 import PageHeader from "@/components/page-header";
export default {
  components: { PageHeader },
  data(){
    return{
      status:'select_status',
      payments:{
        name:'',
        id:'',
      },
      haveSuccess:false,
      haveError:false,
      successMessage:'',
      errorMessage:'',
    }
  },
  methods: {
    edit(){
      const data = {
        id:this.payments.id,
        name:this.payments.name,
      }
      this.$store.dispatch('payments/edit',data).then(() =>{
        this.haveError = false
        this.haveSuccess = true
        this.errorMessage = ''
        sessionStorage.setItem('sessionMessage',this.$store.state.payments.successMessage.data.message)
        this.$router.push({  path: "/payments/list"});
      }).catch(() => {
        this.haveError = true
        this.haveSuccess = false
        this.successMessage = ''
        this.errorMessage = this.$store.state.payments.errorMessage
      })
    },
    getData(){
      this.$store.dispatch('payments/detail', this.$route.params.id).then(() =>{
        this.payments.id    = this.$store.state.payments.data.data.id
        this.payments.name = this.$store.state.payments.data.data.name
      }).catch(() => {
        //this.$router.push('/404')
      })
    }

  },

  mounted(){

    this.getData()
  }
}
</script>