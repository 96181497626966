<template>
    <div class="row">
      <PageHeader :title="title"  />
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
<!--              <div class="col-sm-12 col-md-6">-->
<!--                <div id="tickets-table_length" class="dataTables_length">-->
<!--                  <label class="d-inline-flex align-items-center">-->
<!--                    Show&nbsp;-->
<!--                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries-->
<!--                  </label>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-sm-12 col-md-6">-->
<!--                <div id="tickets-table_filter" class="dataTables_filter text-md-end">-->
<!--                  <label class="d-inline-flex align-items-center">Search:-->
<!--                    <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>-->
<!--                  </label>-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <div class="table-responsive mb-0">
              <b-table striped hover :items="tableData" :fields="fields"></b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="onFiltered"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header"; 
export default {
  components: { PageHeader },
  data() {
    return {
      options:{
        page:1,
        groupBy:[],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false ,
        sortBy: [],
        sortDesc: [],
      },
      tableData: [],
      title: "Manager logs",
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      total:0,
      fields: [
        {key: "id", sortable: true,},
        {key: "name", sortable: true,},
        {key: "ip", sortable: true,},
        {key: "external_data", sortable: true,},
        {key: "external_id", sortable: true,},
        {key: "created_at", sortable: true,},
      ],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      this.tableData = []
      let params = {
        page:this.currentPage,
        per_page:this.perPage
      }
      let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch("account/manager_log",queryString).then(()=>{
        this.tableData = this.$store.state.account.data.data
        this.totalRows   = this.$store.state.account.data.total;
        this.perPage     = this.$store.state.account.data.per_page
        this.currentPage = this.$store.state.account.data.current_page
      }).catch((err)=>{
        console.log(err)
      });
    },
    onFiltered(filteredItems) {
      this.currentPage = filteredItems;
      this.getData()
    },
  }
};
</script>