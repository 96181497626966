<template>
    <div class="row">
      <PageHeader :title="title"   />
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert v-if="haveError" variant="danger" show dismissible class="alert-border alert-border-danger">
              <i class="uil uil-exclamation-octagon font-size-16 text-danger me-2"></i>
              {{ errorMessage }}
            </b-alert>
            <b-alert v-if="haveSuccess" variant="success" show dismissible class="alert-border alert-border-success">
              <i class="uil uil-check font-size-16 text-success me-2"></i>
              {{successMessage}}
            </b-alert>
              <div class="row">
                <div class="col-lg-6">
                  <div>
                    <div class="form-group mb-4">
                      <label for="input-date1">password</label>
                      <input v-model="password" type="password" class="form-control"/>
                    </div>
                    <div class="form-group mb-4">
                      <label for="input-date1">current_password</label>
                      <input v-model="current_password" type="password" class="form-control"/>
                    </div>
                    <div class="form-group mb-4">
                      <label for="input-date1">password_confirmation</label>
                      <input v-model="password_confirmation" type="password" class="form-control"/>
                    </div>
                    <div class="w-100">
                      <b-button variant="primary" size="md" class="float-right mt-4" @click="reset">Create</b-button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header";

export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      haveSuccess:false,
      haveError:false,
      successMessage:'',
      errorMessage:'',
      title: "edit_password",
      password:'',
      current_password:'',
      password_confirmation:'',
    };
  },
  methods:{
    reset(){
      const params = {
        password:this.password,
        current_password:this.current_password,
        password_confirmation:this.password_confirmation
      }
      let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch('account/change_password',queryString).then(() =>{
        this.haveError = false
        this.haveSuccess = true
        this.errorMessage = ''
        this.successMessage = this.$store.state.account.successMessage.data.message
      }).catch(() => {
        this.haveError = true
        this.haveSuccess = false
        this.successMessage = ''
        this.errorMessage = this.$store.state.account.errorMessage
      })
    }
  }
};
</script>