import Axios from 'axios'

export const state = {
    errorMessage:'',
    successMessage:'',
    data:[],
}
export const mutations = {
    error_data(state,data){
        state.errorMessage = data
    },
    success_data(state,data){
        state.successMessage = data
    },
    response_data(state,data){
        state.data = data
    }
}

export const actions = {
    account({ commit }, querystring){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/account?'+querystring, method:"GET"}).then(resp => {
                commit('response_data',resp.data)
                resolve(resp)
            }).catch(err => {
                for(let i in err.response.data){
                    commit('error_data',err.response.data[i][0])
                }
                reject(err)
            })
        })
    },
    session_history({ commit }, querystring){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/managers/sessions?'+querystring, method:"GET"}).then(resp => {
                commit('response_data',resp.data)
                resolve(resp)
            }).catch(err => {
                for(let i in err.response.data){
                    commit('error_data',err.response.data[i][0])
                }
                reject(err)
            })
        })
    },
    manager_log({ commit }, querystring){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/managers/logs?'+querystring, method:"GET"}).then(resp => {
                commit('response_data',resp.data)
                resolve(resp)
            }).catch(err => {
                for(let i in err.response.data){
                    commit('error_data',err.response.data[i][0])
                }
                reject(err)
            })
        })
    },
    change_password({ commit }, data){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/account/change-password', data:data, method:"PUT"}).then(resp => {
                commit('success_data',resp.data)
                resolve(resp)
            }).catch(err => {
                for(let i in err.response.data){
                    commit('error_data',err.response.data[i][0])
                }
                reject(err)
            })
        })
    },
}