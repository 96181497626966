<template>
    <div class="row">
      <div class="col-12">
        <div class="col-xl-12">
          <div class="custom-accordion">
            <div class="card">
              <a href="javascript:void(0)" class="text-dark" v-b-toggle.checkout-billinginfo-collapse>
                <div class="p-4">
                  <div class="media align-items-center"><div class="me-3">
                    <i class="uil uil-filter text-primary h2"></i>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">{{$t("customers.filter_title") }}</h5>
                    <p class="text-muted text-truncate mb-0">{{$t ("customers.filter_subtitle") }}</p>
                  </div>
                  <vue-blob-json-csv file-type="csv" file-name="customer_list" :data="tableCsv" class="btn-group me-2 mb-2 mb-sm-0">
                      <span  class="btn btn-soft-dark btn-dark">
                        <i class="mdi mdi-file-download-outline"></i>
                      </span>
                  </vue-blob-json-csv>
                  <router-link to="/customers/add" class="btn-toolbar p-3">
                    <div class="btn-group me-2 mb-2 mb-sm-0">
                     <div class="btn btn-primary">
                        <span class="btn-primary"><i class="fas fa-plus"></i></span>
                      </div>
                    </div>
                  </router-link>
                  <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                </div>
               </div>
              </a>
              <b-collapse id="checkout-billinginfo-collapse" class="border-top">
                <div class="row p-4">
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mt-4">
                    <b-form-input for="text" :placeholder='$t("select_options.user_id")' v-model="user_id"></b-form-input>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mt-4">
                    <b-form-input for="text" :placeholder='$t("select_options.name")' v-model="name"></b-form-input>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mt-4">
                    <b-form-input for="text" :placeholder='$t("select_options.surname")' v-model="surname"></b-form-input>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mt-4">
                    <b-form-input for="text" :placeholder='$t("select_options.email")' v-model="email"></b-form-input>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mt-4">
                    <b-form-input for="text" :placeholder='$t("select_options.phone")' v-model="phone"></b-form-input>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mt-4">
                    <select v-model="status" class="form-select">
                      <option value="select_status">{{ $t("select_options.select_status") }}</option>
                      <option value="0">{{ $t("select_options.deactivate") }}</option>
                      <option value="1">{{ $t("select_options.active") }}</option>
                    </select>
                  </div>
                  <div class="col-xl-2 col-sm-2  mt-4">
                    <select v-model="perPage" class="form-select">
                      <option value="0" selected>{{ $t("select_options.select_status") }}</option>
                      <option v-for="(k,index) in pageOptions" :value="k">{{ k }}</option>
                    </select>
                  </div>
                  <div class="col-xl-2 col-sm-2 col-md-2  ">
                    <button type="submit" @click="getData" class="btn btn-primary col-xl-12 mt-4 me-4">{{ $t("select_options.search") }}</button>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <div class="repeater"></div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ $t("customers.dashboard_title") }}</h4>
            <b-alert :show="dismissCountDown" v-if="haveSessionMessage" dismissible :variant="variant" @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">
              {{ $t("select_options."+sessionMessage) }}
            </b-alert>
            <div class="table-responsive mb-0">
              <b-table striped hover :items="tableData" :fields="fields">
                <template v-slot:cell(status)="data">
                  <div class="form-check form-switch form-switch-lg" dir="ltr">
                    <input @change="changeStatus(data.item.id)" type="checkbox" class="form-check-input" id="customSwitchsizelg" :checked="data.item.status"/>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  {{data.item.name}} {{data.item.surname}}
                </template>
                <template v-slot:cell(edit)="data">
                  <div class="button-items">
                    <router-link :to="`/customers/edit/${data.item.id}`"
                                 href="javascript:void(0);" class="btn btn-warning">
                      <i class="bx bx-edit-alt "></i>
                    </router-link>
                  </div>
                </template>
                <template v-slot:cell(detail)="data">
                  <div class="button-items">
                    <router-link :to="`/customers/detail/${data.item.id}`"
                                 href="javascript:void(0);" class="btn btn-primary">
                      <i class="bx bxs-user-detail "></i>
                    </router-link>
                  </div>
                </template>
                <template v-slot:cell(delete)="data">
                  <div class="button-items">
                    <b-button variant="danger" @click="del(data.item.id)"><i class="far fa-trash-alt"></i></b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="onFiltered"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Switches from "vue-switches";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
export default {
  components: { PageHeader, Switches},
  data() {
    return {
      tableCsv:[],
      variant:"success",
      haveSessionMessage:false,
      sessionMessage:'',
      dismissSecs: 5,
      dismissCountDown:0,
      user_id:'',
      surname:'',
      name:'',
      email:'',
      phone:'',
      add_customers:true,
      options:{
        page:1,
        groupBy:[],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false ,
        sortBy: [],
        sortDesc: [],
      },
      tableData: [],
      title: "Advanced",
      items: [
        {
          text: "Tables",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {key: "id", sortable: true},
        {key: "user_id", sortable: true},
        "name",
        {key: "phone", sortable: true},
        {key: "email", sortable: true},
        {key: "status", sortable: true},
        'affiliate',
        {key: "created_at", sortable: true},
        "edit",
        "detail",
        "delete"
      ],
      id:'',
      status:'select_status',
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    changeStatus(id){
      this.$store.dispatch("customers/status", id).then(()=>{
        this.haveSessionMessage = true
        this.sessionMessage = this.$store.state.customers.successMessage.data.message
        this.variant='success'
        this.showAlert()
      }).catch(()=>{
        this.haveSessionMessage = true
        this.sessionMessage = this.$store.state.customers.errorMessage
        this.variant='danger'
        this.showAlert()
      });
    },
    del(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons.fire({
        title: this.$t("alerts.are_you_sure"),
        text: this.$t("alerts.you_want_to_delete_this"),
        icon: "warning",
        confirmButtonText: this.$t("alerts.yes"),
        cancelButtonText: this.$t("alerts.no"),
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('customers/delete',id).then(()=>{
            swalWithBootstrapButtons.fire(this.$t("alerts.deleted"), this.$store.state.customers.successMessage.data.messages, "success");
            this.getData()
          }).catch(()=>{
            swalWithBootstrapButtons.fire("warning", this.$t("alerts.data_not_found"), "warning");
          })
        }
      });
    },
    getData(){
      this.tableData = []
      let params = {
        user_id:this.user_id,
        surname:this.surname,
        email:this.email,
        phone:this.phone,
        page:this.currentPage,
        per_page: this.perPage === 'select_per_page' ? 10 : this.perPage,
        id:this.id,
        name:this.name,
        status:this.status === 'select_status' ? '' : this.status
      }
      let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch("customers/list",queryString).then(()=>{
        let customers = this.$store.state.customers.data.data.data
        for(let i in customers){
          this.tableData.push({
            id:customers[i].id,
            affiliate_id:customers[i].affiliate_id,
            affiliate_name:customers[i].affiliate_name,
            affiliate_surname:customers[i].affiliate_surname,
            created_at:customers[i].created_at,
            email:customers[i].email,
            name:customers[i].name,
            phone:customers[i].phone,
            status:customers[i].status,
            surname:customers[i].surname,
            user_id:customers[i].user_id,
            affiliate: customers[i].affiliate_name !== null ? customers[i].affiliate_name+ " " + customers[i].affiliate_surname  : ""
          })
          this.tableCsv.push({
            id:customers[i].id,
            affiliate_id:customers[i].affiliate_id,
            affiliate_name:customers[i].affiliate_name,
            affiliate_surname:customers[i].affiliate_surname,
            created_at:customers[i].created_at,
            email:customers[i].email,
            name:customers[i].name,
            phone:customers[i].phone,
            status:customers[i].status === 1 ? this.$t("alerts.active") : this.$t("alerts.deactivated"),
            surname:customers[i].surname,
            user_id:customers[i].user_id,
            affiliate: customers[i].affiliate_name !== null ? customers[i].affiliate_name+ " " + customers[i].affiliate_surname  : ""
          })
        }
        this.totalRows   = this.$store.state.customers.data.data.total;
        this.perPage     = this.$store.state.customers.data.data.per_page
        this.currentPage = this.$store.state.customers.data.data.current_page
      }).catch(()=>{
         this.$router.push('/404')
      });
    },
    onFiltered(filteredItems) {
      this.currentPage = filteredItems;
      this.getData()
    },
  },
  mounted() {
    if(sessionStorage.getItem('sessionMessage')){
      this.haveSessionMessage = true
      this.sessionMessage = sessionStorage.getItem('sessionMessage')
      sessionStorage.removeItem('sessionMessage')
      this.showAlert()
    }
    this.getData()
    this.totalRows = this.items.length;
  }
};
</script>