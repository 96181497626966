<template>
    <div class="row mb-4">
      <PageHeader :title="title" />
      <div class="col-xl-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
<!--              <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">-->
<!--                <template v-slot:button-content>-->
<!--                  <i class="uil uil-ellipsis-v"></i>-->
<!--                </template>-->
<!--                <a class="dropdown-item" href="#">Edit</a>-->
<!--                <a class="dropdown-item" href="#">Action</a>-->
<!--                <a class="dropdown-item" href="#">Remove</a>-->
<!--              </b-dropdown>-->
              <div class="clearfix"></div>
              <div>
                <img src="@/assets/images/users/avatar-7.jpg" alt class="avatar-lg rounded-circle img-thumbnail" />
              </div>

              <h5 class="mt-3 mb-1">{{ personalInformation.name }} {{personalInformation.surname}}</h5>
<!--              <p class="text-muted">UI/UX Designer</p>-->

<!--              <div class="mt-4">-->
<!--                <button type="button" class="btn btn-light btn-sm">-->
<!--                  <i class="uil uil-envelope-alt me-2"></i> Message-->
<!--                </button>-->
<!--              </div>-->
            </div>

            <hr class="my-4" />

            <div class="text-muted">
<!--              <h5 class="font-size-16">About</h5>-->
<!--              <p>Hi I'm Marcus,has been the industry's standard dummy text To an English person, it will seem like simplified English, as -->
<!--                a skeptical Cambridge.</p>-->
              <div class="table-responsive mt-4 mb-0">
                <div>
                  <p class="mb-1">{{ $t("select_options.name") }} :</p>
                  <h5 class="font-size-16">{{personalInformation.name}}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">{{ $t("select_options.username") }} :</p>
                  <h5 class="font-size-16">{{personalInformation.username}}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">{{ $t("select_options.email") }} :</p>
                  <h5 class="font-size-16">{{personalInformation.email}}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">{{ $t("select_options.status") }} :</p>
                  <h5 class="font-size-16" v-if="personalInformation.status">{{ $t("alerts.active") }}</h5>
                  <h5 class="font-size-16" v-else>{{ $t("alerts.deactivated") }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-8">
        <div class="card mb-0">
          <b-tabs content-class="p-4" justified class="nav-tabs-custom">
            <b-tab active>
              <template v-slot:title>
                <i class="uil uil-clipboard-notes font-size-20"></i>
                <span class="d-none d-sm-block" @click="getLog">{{ $t("managers.manager_log") }}</span>
              </template>
                <div>
                  <div class="table-responsive mb-0">
                    <b-table striped hover :items="tableData" :fields="fields"></b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="onFiltered"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="uil uil-clipboard-notes font-size-20"></i>
                <span class="d-none d-sm-block" @click="getSession">{{ $t("managers.session_history") }}</span>
              </template>
              <div>
                <div class="table-responsive mb-0">
                  <b-table striped hover :items="tableDataSession" :fields="fieldsSession"></b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination v-model="currentPageSession" :total-rows="totalRowsSession" :per-page="perPageSession" @change="onFilteredSession"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header";
export default {
  components: { PageHeader },
  data() {
    return {
      tableDataSession:[],
      totalRowsSession:1,
      perPageSession:10,
      currentPageSession :1,
      personalInformation:[],
      title: "Manger detail",
      tableData: [],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      total:0,
      fields: [
        {key: "id", sortable: true,},
        {key: "name", sortable: true,},
        {key: "ip", sortable: true,},
        {key: "external_data", sortable: true,},
        {key: "external_id", sortable: true,},
        {key: "created_at", sortable: true,},
      ],
      fieldsSession : [
        {key: "id", sortable: true,},
        {key: "ip", sortable: true,},
        {key: "user_agent", sortable: true,},
        {key: "created_at", sortable: true,},
      ],

    };
  },
  methods:{
    getLog(){
      this.tableData = []
      let params = {
        manager_id:this.$route.params.id,
        page:this.currentPage,
        per_page:this.perPage
      }
      let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch("account/manager_log",queryString).then(()=>{
        this.tableData = this.$store.state.account.data.data
        this.totalRows   = this.$store.state.account.data.total;
        this.perPage     = this.$store.state.account.data.per_page
        this.currentPage = this.$store.state.account.data.current_page
      }).catch((err)=>{
        console.log(err)
      });
    },
    getSession(){
      this.tableDataSession = []
      let params = {
        manager_id:this.$route.params.id,
        page:this.currentPageSession,
        per_page:this.perPageSession
      }
      let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch("account/session_history",queryString).then(()=>{
        this.tableDataSession = this.$store.state.account.data.data
        this.totalRowsSession   = this.$store.state.account.data.total;
        this.perPageSession     = this.$store.state.account.data.per_page
        this.currentPageSession = this.$store.state.account.data.current_page

      }).catch((err)=>{
        console.log(err)
      });
    },
    onFiltered(filteredItems) {
      this.currentPage = filteredItems;
      this.getLog()
    },
    onFilteredSession(filteredItems) {
      this.currentPageSession = filteredItems;
      this.getSession()
    },
  },
  mounted() {
    this.getLog()
    this.$store.dispatch('managers/detail',this.$route.params.id).then(()=>{
      this.personalInformation = this.$store.state.managers.data.data
    }).catch(() => {
      this.$router.push('/404')
    })
  }
};
</script>