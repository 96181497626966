<template>
    <div class="row">
      <PageHeader :title='$t("affiliates.add_title")'  />
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert v-if="haveError" variant="danger" show dismissible class="alert-border alert-border-danger">
              <i class="uil uil-exclamation-octagon font-size-16 text-danger me-2"></i>
              {{ errorMessage }}
            </b-alert>
            <b-alert v-if="haveSuccess" variant="success" show dismissible class="alert-border alert-border-success">
              <i class="uil uil-check font-size-16 text-success me-2"></i>
              {{successMessage}}
            </b-alert>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.name")' v-model="add_affiliate.name"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.surname")' v-model="add_affiliate.surname"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.username")' v-model="add_affiliate.username"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.percent")' v-model="add_affiliate.percent"></b-form-input>
            </div>
            <div class="w-100">
              <b-button variant="primary" size="md" class="float-right col-12 mt-4" @click="add">{{ $t("select_options.create") }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header";
export default {
  components: { PageHeader },
  data() {
    return {
      add_affiliate:{
        name:'',
        surname:'',
        username:'',
        percent:'',
      },
      haveSuccess:false,
      haveError:false,
      successMessage:'',
      errorMessage:'',
    };
  },
  methods:{
    add(){
      const params = {
        name:this.add_affiliate.name,
        surname:this.add_affiliate.surname,
        username:this.add_affiliate.username,
        percent:this.add_affiliate.percent
      }
      let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch('affiliates/add',queryString).then(() =>{
        this.haveError = false
        this.haveSuccess = true
        this.errorMessage = ''
        sessionStorage.setItem('sessionMessage',this.$store.state.affiliates.successMessage.data.message)
        this.$router.push({path: "/affiliates/list"});
      }).catch(() => {
        this.haveError = true
        this.haveSuccess = false
        this.successMessage = ''
        this.errorMessage = this.$store.state.affiliates.errorMessage
      })
    },
  }
};
</script>