<template>
  <div id="app">
    <Layout v-if="this.$route.name !=='login'">
      <router-view />
    </Layout>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>
<script>
import Layout from "@/views/layouts/main";
import { notificationMethods } from "@/state/helpers";
export default {
  components: {Layout},
  methods: {
    clearNotification: notificationMethods.clear,
  },
  watch: {
    $route(to, from) {
      this.clearNotification();
    },
  },
  mounted(){
    let profile = localStorage.getItem('auth.currentUser')
    if(profile){
      this.$store.dispatch('auth/updateProfile')
    }
  }
};
</script>