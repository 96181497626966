<template>
<div class="col-xl-6">
    <div class="card">
        <div class="card-body">
<!--            <div class="float-end">-->
<!--                <b-dropdown variant="white" toggle-class="p-0" menu-class="dropdown-menu-end">-->
<!--                    <template v-slot:button-content>-->
<!--                        <span class="text-muted">-->
<!--                            {{$t('dashboard.third_line.sort.text')}}-->
<!--                            <i class="mdi mdi-chevron-down ms-1"></i>-->
<!--                        </span>-->
<!--                    </template>-->
<!--                    <b-dropdown-item href="#">{{$t('dashboard.third_line.sort.text2')}}</b-dropdown-item>-->
<!--                    <b-dropdown-item href="#">{{$t('dashboard.third_line.sort.text3')}}</b-dropdown-item>-->
<!--                    <b-dropdown-item href="#">Join Date{{$t('dashboard.third_line.sort.text4')}}</b-dropdown-item>-->
<!--                </b-dropdown>-->
<!--            </div>-->
            <h4 class="card-title mb-4">{{$t('dashboard.third_line.title.text')}}</h4>

            <div data-simplebar style="max-height: 336px;">
                <div class="table-responsive">
                    <table class="table table-borderless table-centered table-nowrap">
                        <tbody>
                            <tr v-for="(item, index) in users" :key="index">
                                <td style="width: 20px;">
                                    <img src="@/assets/images/users/avatar-7.jpg" class="avatar-xs rounded-circle" alt="..." />
                                </td>
                                <td>
                                    <h6 class="font-size-15 mb-1 fw-normal">{{item.name}}</h6>
                                    <p class="text-muted font-size-13 mb-0">
                                        <i class="mdi mdi-map-marker"></i>
                                        {{item.location}}
                                    </p>
                                </td>
                              <td>
                                <span class="badge font-size-12 " :class="{'bg-soft-success': item.status === 1}">success</span>
<!--                                <span class="badge font-size-12 " :class="{'bg-soft-danger': item.status === 2}">danger</span>-->
<!--                                <span class="badge font-size-12 " :class="{'bg-soft-info': item.status === 3}">info</span>-->
<!--                                <span class="badge font-size-12 " :class="{'bg-soft-warning': item.status === 4}">warning</span>-->
                              </td>
                                <td class="text-muted fw-semibold text-end">
                                    <i class="icon-xs icon me-2 text-success" data-feather="trending-up"></i>
                                    {{item.created_at}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
  props:['users'],
  data() {
    return {
      // users: [{

      //   location: "Turkey",
      //   status: "Success",
      //   price: "$500.00",
      // },

    // {
      // "id": 22,
      // "name":
      // "test cust",
      // "surname": "sss",
      // "phone": "1323424234",
      // "email": "iks@Ika.ge",
      // "status": 1,
      // "affiliate_id": 17,
      // "created_at": "2022-05-08 11:10:29", "deleted": 0
      // },
    };
  },
};
</script>