<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="col-xl-12">
          <div class="custom-accordion">
            <div class="card">
              <a href="javascript: void(0);" class="text-dark"  v-b-toggle.checkout-billinginfo-collapse>
                <div class="p-4">
                  <div class="media align-items-center"><div class="me-3">
                    <i class="uil uil-filter text-primary h2"></i>
                  </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Filter</h5>
                      <p class="text-muted text-truncate mb-0">reports methods filter area</p>
                    </div>
                    <vue-blob-json-csv file-type="csv" file-name="payment_list" :data="tableCsv" class="btn-group me-2 mb-2 mb-sm-0">
                      <span  class="btn btn-soft-dark btn-dark">
                        <i class="mdi mdi-file-download-outline"></i>
                      </span>
                    </vue-blob-json-csv>
                    <router-link to="/payments/add" class="btn-toolbar p-3">
                      <div class="btn-group me-2 mb-2 mb-sm-0">
                        <div type="button" class="btn btn-primary">
                          <span  class="btn-primary"><i class="fas fa-plus"></i></span>
                        </div>
                      </div>
                    </router-link>
                    <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                  </div>
                </div>
              </a>
              <b-collapse id="checkout-billinginfo-collapse" class="border-top"  >
                <div class="row p-4 ">
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mt-4">
                    <b-form-input for="text" placeholder="ID" v-model="id"></b-form-input>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mt-4">
                    <b-form-input for="text" placeholder="Name" v-model="name"></b-form-input>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mt-4">
                    <select v-model="status" class="form-select">
                      <option value="select_status">select_status</option>
                      <option value="0">deactive</option>
                      <option value="1">active</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-lg-2col-md-2 col-sm-2 mt-4">
                    <select v-model="perPage" class="form-select">
                      <option value="select_per_page">select_per_page</option>
                      <option v-for="(k,index) in pageOptions" :value="k">{{ k }}</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 ">
                    <button type="submit" @click="getData" class="btn btn-primary col-xl-12 mt-4 me-4">search</button>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <div class="repeater"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">payment methods</h4>
            <b-alert :show="dismissCountDown" v-if="haveSessionMessage" dismissible :variant="variant" @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">
              {{ sessionMessage }}
            </b-alert>
            <div class="table-responsive mb-0">
              <b-table striped hover :items="tableData" :fields="fields">
                <template v-slot:cell(status)="data">
                  <div class="form-check form-switch form-switch-lg" dir="ltr">
                    <input @change="changeStatus(data.item.id)" type="checkbox" class="form-check-input" id="customSwitchsizelg" :checked="data.item.status"/>
                  </div>
                </template>
                <template v-slot:cell(edit)="data">
                  <ul class="list-inline button-items mb-0">
                    <li class="list-inline-item">
                      <div class="button-items">
                        <router-link :to="`/payments/edit/${data.item.id}`" href="javascript:void(0);" class="btn btn-warning">
                          <i class="bx bx-edit-alt "></i>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </template>
                <template v-slot:cell(delete)="data">
                  <ul class="list-inline button-items mb-0">
                    <li class="list-inline-item">
                      <div class="button-items">
                        <b-button variant="danger" @click="del(data.item.id)" ><i class="mdi mdi-window-close"></i></b-button>
                      </div>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="onFiltered"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      tableCsv:[],
      variant:"success",
      haveSessionMessage:false,
      sessionMessage:'',
      dismissSecs: 5,
      dismissCountDown:0,
      managerData:[],
      edit_manager:false,
      haveSuccess:false,
      add_manager:true,
      options:{
        page:1,
        groupBy:[],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false ,
        sortBy: [],
        sortDesc: [],
      },
      tableData: [],
      title: "Manager logs",
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 'select_per_page',
      pageOptions: [10, 20, 30, 50, 100],
      start_date:'',
      end_date:'',
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      total:0,
      fields: [
        {key: "id", sortable: true},
        {key: "name", sortable: true},
        "status",
        {key: "created_at", sortable: true},
        "edit",
        "delete"
      ],
      id:'',
      name:'',
      status:'select_status',
    };
  },
  mounted() {
    this.$router.push('/coming_soon')
    // if(sessionStorage.getItem('sessionMessage')){
    //   this.haveSessionMessage = true
    //   this.sessionMessage = sessionStorage.getItem('sessionMessage')
    //   sessionStorage.removeItem('sessionMessage')
    //   this.showAlert()
    // }
    // this.start_date = this.getLastDay(1, ' 00:00:00')
    // this.end_date   = this.getLastDay(0, ' 23:59:59')
    // this.getData()
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    changeStatus(id){
      this.$store.dispatch("reports/status", id).then(()=>{
        this.haveSessionMessage = true
        this.variant='success'
        this.sessionMessage = this.$store.state.payments.successMessage.data.message
        this.showAlert()
      }).catch(()=>{
        this.haveSessionMessage = true
        this.variant='danger'
        this.sessionMessage = this.$store.state.payments.errorMessage
        this.showAlert()
      });
    },
    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    del(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons.fire({
        title: "are_you_sure",
        text: "you_want_to_delete_this",
        icon: "warning",
        confirmButtonText: "yes",
        cancelButtonText: "no",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('reports/delete',id).then(()=>{
            swalWithBootstrapButtons.fire("deleted", this.$store.state.payments.successMessage.data.messages, "success");
            this.getData()
          }).catch(()=>{
            swalWithBootstrapButtons.fire("warning", 'data_not_found', "warning");
          })
        }
      });
    },
    getData(){
      this.tableData = []
      let params = {
        page:this.currentPage,
        per_page: this.perPage === 'select_per_page' ? 10 : this.perPage,
        id:this.id,
        name:this.name,
        status:this.status === 'select_status' ? '' : this.status
      }
      let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
      this.$store.dispatch("reports/list",queryString).then(()=>{
        this.tableData   = this.$store.state.payments.data.data.data
        let payments = this.$store.state.payments.data.data.data
        for(let i in payments) {
          this.tableCsv.push({
            id: payments[i].id,
            name: payments[i].name,
            status:payments[i].status === 1 ? this.$t("managers.active.text1") : this.$t("managers.deactive.text1"),
            created_at: payments[i].created_at,
          })
        }
        this.totalRows   = this.$store.state.payments.data.data.total;
        this.perPage     = this.$store.state.payments.data.data.per_page
        this.currentPage = this.$store.state.payments.data.data.current_page
      }).catch(()=>{
        this.$router.push('/404')
      });
    },
    onFiltered(filteredItems) {
      this.currentPage = filteredItems;
      this.getData()
    },
  }
};
</script>