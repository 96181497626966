<script>
import countTo from "vue-count-to";
/**
 * Stat component
 */
export default {

  props:['dashboard'],
    components: {
        countTo
    },
    data() {
        return {
            series: [{
                data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
            }],
            chartOptions: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            growthChartOptions: {
                fill: {
                    colors: ["#f1b44c"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            orderseries: [70],
            orderRadial: {
                fill: {
                    colors: ["#34c38f"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            },
            customerseries: [55],
            customerRadial: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            }
        };
    }
};
</script>

<template>
<div class="row">
    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end mt-2">
                    <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart>
                </div>
                <div>
                    <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">
                            <countTo :startVal="1" :endVal="dashboard.total_customer" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <p class="text-muted mb-0">{{$t('dashboard.first_line.one.text')}}r</p>
                </div>
                <p class="text-muted mt-3 mb-0">
                    <span class="text-success me-1">
                        <i class="mdi mdi-arrow-up-bold me-1"></i>0.0%
                    </span>
                    {{$t('dashboard.first_line.one.text2')}}
                </p>
            </div>
        </div>
    </div>
    <!-- end col-->

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end mt-2">
                  <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions" :series="series"></apexchart>
                </div>
                <div>
                    <h4 class="mb-1 mt-1">
                      $
                        <span data-plugin="counterup">
                            <countTo :startVal="1" :endVal="dashboard.daily_transactions" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <p class="text-muted mb-0">{{$t('dashboard.first_line.two.text')}}</p>
                </div>
                <p class="text-muted mt-3 mb-0">
                    <span class="text-danger me-1">
                        <i class="mdi mdi-arrow-down-bold me-1"></i>0.0%
                    </span>
                    {{$t('dashboard.first_line.two.text2')}}

                </p>
            </div>
        </div>
    </div>


    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end mt-2">
                    <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions" :series="series"></apexchart>
                </div>
              <div>
                <h4 class="mb-1 mt-1">
                  $

                  <span data-plugin="counterup">
                            <countTo :startVal="1" :endVal="dashboard.monthly_transactions" :duration="2000"></countTo>
                        </span>
                </h4>
                <p class="text-muted mb-0">{{$t('dashboard.first_line.three.text')}}</p>
              </div>
                <p class="text-muted mt-3 mb-0">
                    <span class="text-success me-1">
                        <i class="mdi mdi-arrow-up-bold me-1"></i>0.0%
                    </span>
                    {{$t('dashboard.first_line.three.text2')}}
                </p>
            </div>
        </div>
    </div>
    <!-- end col-->



  <div class="col-md-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="orderRadial" :series="orderseries"></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
                          <span data-plugin="counterup">
                              <countTo :startVal="1" :endVal="dashboard.total_affiliates" :duration="2000"></countTo>
                          </span>
            </h4>
            <p class="text-muted mb-0">{{$t('dashboard.first_line.four.text')}}</p>
          </div>
          <p class="text-muted mt-3 mb-0">
                      <span class="text-danger me-1">
                          <i class="mdi mdi-arrow-down-bold me-1"></i>0.0%
                      </span>
            {{$t('dashboard.first_line.four.text2')}}
          </p>
        </div>
      </div>
    </div>




</div>
<!-- end row-->
</template>
