
import dashboard from '../views/pages/dashboard/index'

import customers_list from '../views/pages/customers/list'
import customers_edit from '../views/pages/customers/edit'
import customers_add from '../views/pages/customers/add'
import customers_detail from '../views/pages/customers/detail'


import affiliates_list from '../views/pages/affiliates/list'
import affiliate_edit from '../views/pages/affiliates/edit'
import affiliate_add from '../views/pages/affiliates/add'
import affiliate_detail from '../views/pages/affiliates/detail'

import transactions_list from '../views/pages/transactions/list'
import transactions_add from '../views/pages/transactions/add'
import transactions_edit from '../views/pages/transactions/edit'
import transactions_detail from '../views/pages/transactions/detail'

import managers_list from '../views/pages/managers/list'
import managers_edit from '../views/pages/managers/edit'
import managers_add from '../views/pages/managers/add'
import managers_detail from '../views/pages/managers/detail'

import payment_methods from '../views/pages/payments/list'
import payment_add from '../views/pages/payments/add'
import payment_edit from '../views/pages/payments/edit'

import account from '../views/pages/account/account'
import session_history from '../views/pages/account/session_history'
import manager_log from '../views/pages/account/log'
import change_password from '../views/pages/account/change_password'

import financial_report from '../views/pages/reports/financial_report/list'
import customers_report from '../views/pages/reports/customers_report/list'


import coming_soon from '../components/coming_soon'
import login from '../views/pages/login'
import logout from '../views/pages/logout'
import Notfound from '../components/404.vue'
export default [
    { path: '/', name: 'home',  component:  dashboard, meta: {authRequired: true} },

    //customers
    { path: '/customers/list', name: 'customers',  component:  customers_list, meta: {authRequired: true} },
    { path: '/customers/edit/:id', name: 'customers',  component:  customers_edit, meta: {authRequired: true} },
    { path: '/customers/add', name: 'customers',  component:  customers_add, meta: {authRequired: true} },
    { path: '/customers/detail/:id', name: 'customers',  component:  customers_detail, meta: {authRequired: true} },

    //affiliates
    { path: '/affiliates/list', name: 'affiliates',  component:  affiliates_list, meta: {authRequired: true} },
    { path: '/affiliates/edit/:id', name: 'affiliates',  component:  affiliate_edit, meta: {authRequired: true} },
    { path: '/affiliates/add', name: 'affiliates',  component:  affiliate_add, meta: {authRequired: true} },
    { path: '/affiliates/detail/:id', name: 'affiliates',  component:  affiliate_detail, meta: {authRequired: true} },

    //managers
    { path: '/managers/list', name: 'managers',  component:  managers_list, meta: {authRequired: true} },
    { path: '/managers/edit/:id', name: 'managers',  component:  managers_edit, meta: {authRequired: true} },
    { path: '/managers/add', name: 'managers',  component:  managers_add, meta: {authRequired: true} },
    { path: '/managers/detail/:id', name: 'managers',  component:  managers_detail, meta: {authRequired: true} },

    //transactions
    { path: '/transactions/list', name: 'transactions',  component:  transactions_list, meta: {authRequired: true} },
    { path: '/transactions/add', name: 'transactions',  component:  transactions_add, meta: {authRequired: true} },
    { path: '/transactions/edit/:id', name: 'transactions',  component:  transactions_edit, meta: {authRequired: true} },
    { path: '/transactions/detail/:id', name: 'transactions',  component:  transactions_detail, meta: {authRequired: true} },

    //account
    { path: '/account/settings', name: 'account',  component:  account, meta: {authRequired: true} },
    { path: '/account/session_history', name: 'session_history',  component:  session_history, meta: {authRequired: true} },
    { path: '/account/log', name: 'manager_log',  component:  manager_log, meta: {authRequired: true} },
    { path: '/account/change_password', name: 'manager_log',  component:  change_password, meta: {authRequired: true} },

    { path: '/logout', name: 'logout',  component:  logout, meta: {authRequired: true} },
    { path: '/login', name: 'login',  component:  login, meta: {authRequired: false,} },

    //payments
    { path: '/payments/list', name: 'payment_methods',  component:  payment_methods, meta: {authRequired: true} },
    { path: '/payments/add', name: 'payment_methods',  component:  payment_add, meta: {authRequired: true} },
    { path: '/payments/edit/:id', name: 'payment_methods',  component:  payment_edit, meta: {authRequired: true} },


    //reports

    { path: '/financial_report/list', name: 'financial_report',  component:  financial_report, meta: {authRequired: true} },
    { path: '/customers_report/list', name: 'customers_report',  component:  customers_report, meta: {authRequired: true} },


    {path: '/coming_soon', name: 'coming_soon', component: coming_soon, meta:{requiresAuth:true}},
    //404
    {path: '/*', name: '*', component: Notfound, meta:{requiresAuth:true}},
]