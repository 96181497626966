import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import Axios from 'axios'
import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'
import moment from 'moment'
import VueBlobJsonCsv from 'vue-blob-json-csv';
Vue.prototype.moment = moment
import App from './App.vue'
import VueMask from 'v-mask'
Vue.config.productionTip = false
import Lightbox from 'vue-easy-lightbox'

Axios.interceptors.response.use(
  function (response){ return response },
  function (error){
    if(error.response.status === 401){
      if(error.response.config.url !== '/login'){
        store.dispatch('logout')
        router.push('/login').catch(() => {})
      }
    }
    return Promise.reject(error)
  }
);
Vue.prototype.$http = Axios;
const token = localStorage.getItem('access_token')
Vue.prototype.$http.defaults.baseURL = process.env.API_ENDPOINT
Vue.prototype.$http.defaults.headers.common['Content-Type'] = 'application/json';
Vue.prototype.$http.defaults.headers.common['Accept'] = 'application/json'
if (token){
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
import '@/assets/scss/app.scss'
Vue.mixin({
    methods:{
        backToTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        getLastDay(d = 0,time){
            let dayToSecond = d * 24 * 60 * 60
            let nowTime = Math.floor(Date.now() / 1000)
            return  moment.unix((nowTime - dayToSecond)).format("Y-MM-DDTHH:mm:ss");
        },
    },
})
Vue.use(VueBlobJsonCsv)
Vue.use(Lightbox)
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(require("vue-chartist"));
Vue.use(VueMask);
new Vue({router, store, i18n, render: h => h(App),}).$mount('#app')