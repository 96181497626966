<template>

  <div class="row">
    <PageHeader :title='$t("transaction.edit_title")'  />
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <b-alert v-if="haveError" variant="danger" show dismissible class="alert-border alert-border-danger">
            <i class="uil uil-exclamation-octagon font-size-16 text-danger me-2"></i>
            {{ errorMessage }}
          </b-alert>
          <b-alert v-if="haveSuccess" variant="success" show dismissible class="alert-border alert-border-success">
            <i class="uil uil-check font-size-16 text-success me-2"></i>
            {{successMessage}}
          </b-alert>
          <div class="col-lg-12 mt-4">
            <multiselect v-model="edit_transaction.customer_id" track-by="full_name"
                         label="full_name" :placeholder='$t("select_options.select_customer")' :options="customers_list" :searchable="true" :allow-empty="false">
              <template slot="singleLabel" slot-scope="{ option }">
                <strong>  {{  customers_list.filter(obj => {
                return obj.id === edit_transaction.customer_id
              })[0].full_name }}</strong></template>
            </multiselect>
          </div>
          <div class="col-lg-12 mt-4">
            <multiselect v-model="edit_transaction.payment_id"   track-by="name"
                         label="name" :placeholder='$t("select_options.select_payment_method")' :options="payments_list" :searchable="true" :allow-empty="false">
              <template slot="singleLabel" slot-scope="{ option }">
                <strong>{{ payments_list.filter(obj => {
                return obj.id === edit_transaction.payment_id
              })[0].name }}</strong></template>
            </multiselect>
          </div>
          <div class="col-lg-12 mt-4">
            <select v-model="edit_transaction.type" class="form-select">
              <option value="select_status">{{ $t("select_options.select_status") }}</option>
              <option value="1">{{ $t("select_options.deposit") }}</option>
              <option value="2">{{ $t("select_options.withdraw") }}</option>
            </select>
          </div>
          <div class="col-lg-12 mt-4">
            <b-form-input :placeholder='$t("select_options.amount")' v-model="edit_transaction.amount"></b-form-input>
          </div>
          <div class="col-lg-12 mt-4">
            <b-form-input :placeholder='$t("select_options.notes")' v-model="edit_transaction.notes"></b-form-input>
          </div>
          <div class="w-100">
            <b-button variant="primary" size="md"  class="float-right col-12 mt-4"  @click="edit">update</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
export default {
  components: { PageHeader, Multiselect},
  data() {
    return {
      customers_list:[],
      payments_list:[],
      edit_transaction:{
        id:"",
        customer_id:"",
        payment_id:"",
        type:"select_status",
        amount:"",
        notes:"",
      },
      haveSuccess:false,
      haveError:false,
      successMessage:'',
      errorMessage:'',
    };
  },
  methods:{
    edit(){
      const params = {
        id:this.edit_transaction.id,
        customer_id:this.edit_transaction.customer_id.id,
        payment_id:this.edit_transaction.payment_id.id,
        type:this.edit_transaction.type,
        amount:this.edit_transaction.amount,
        notes:this.edit_transaction.notes,
      }
      this.$store.dispatch('transactions/edit',params).then(() =>{
        this.haveError = false
        this.haveSuccess = true
        this.errorMessage = ''
        sessionStorage.setItem('sessionMessage',this.$store.state.transactions.successMessage.data.message)
        this.$router.push({  path: "/transactions/list"});
      }).catch(() => {
        this.haveError = true
        this.haveSuccess = false
        this.successMessage = ''
        this.errorMessage = this.$store.state.transactions.errorMessage
      })
    },
  },
  mounted() {
    this.$store.dispatch("transactions/detail", this.$route.params.id).then(()=>{
      this.edit_transaction.id = this.$store.state.transactions.data.data.id;
      this.edit_transaction.customer_id = this.$store.state.transactions.data.data.customer_id;
      this.edit_transaction.payment_id = this.$store.state.transactions.data.data.payment_id
      this.edit_transaction.type = this.$store.state.transactions.data.data.type
      this.edit_transaction.amount = this.$store.state.transactions.data.data.amount
      this.edit_transaction.notes = this.$store.state.transactions.data.data.notes
    })
    let params = {per_page:100}
    let queryString = Object.keys(params).map(key => params[key] ? key + '=' + params[key] :'').join('&');
    this.$store.dispatch("payments/list",queryString).then(()=>{
      this.payments_list =  this.$store.state.payments.data.data.data
    })

    this.$store.dispatch("customers/list",queryString).then(()=>{
      //this.customers_list =  this.$store.state.customers.data.data.data
      let customers = this.$store.state.customers.data.data.data
      for(let i in customers){
        this.customers_list.push({
          id : customers[i].id,
          affiliate_id : customers[i].affiliate_id,
          status : customers[i].status,
          full_name : customers[i].user_id + " | " +customers[i].name +" "+ customers[i].surname,
          username : customers[i].username,
          affiliate_name : customers[i].affiliate_name,
          affiliate_surname : customers[i].affiliate_surname,
          email : customers[i].email,
          phone : customers[i].phone,
          user_id : customers[i].user_id,
          created_at : customers[i].created_at,
        })
      }
    })
  }
};
</script>