<template>
    <div class="row">
      <PageHeader :title='$t("affiliates.edit_title")'  />
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert v-if="haveError" variant="danger" show dismissible class="alert-border alert-border-danger">
              <i class="uil uil-exclamation-octagon font-size-16 text-danger me-2"></i>
              {{ errorMessage }}
            </b-alert>
            <b-alert v-if="haveSuccess" variant="success" show dismissible class="alert-border alert-border-success">
              <i class="uil uil-check font-size-16 text-success me-2"></i>
              {{successMessage}}
            </b-alert>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.name")' v-model="edit_affiliate.name"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.surname")' v-model="edit_affiliate.surname"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.username")' v-model="edit_affiliate.username"></b-form-input>
            </div>
            <div class="col-lg-12 mt-4">
              <b-form-input :placeholder='$t("select_options.percent")' v-model="edit_affiliate.percent"></b-form-input>
            </div>
            <div class="w-100">
              <b-button variant="primary" size="md" class="float-right col-12 mt-4" @click="edit">{{ $t("select_options.update") }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import PageHeader from "@/components/page-header";
export default {
  components: { PageHeader },
  data() {
    return {
      edit_affiliate:{
        name:'',
        surname:'',
        username:'',
        percent:'',
      },
      haveSuccess:false,
      haveError:false,
      successMessage:'',
      errorMessage:'',
    };
  },
  methods:{
    edit(){
      const data = {
        id:this.edit_affiliate.id,
        name:this.edit_affiliate.name,
        surname:this.edit_affiliate.surname,
        username:this.edit_affiliate.username,
        percent:this.edit_affiliate.percent
      }
      this.$store.dispatch('affiliates/edit',data).then(() =>{
        this.haveError = false
        this.haveSuccess = true
        this.errorMessage = ''
        sessionStorage.setItem('sessionMessage',this.$store.state.affiliates.successMessage.data.message)
        this.$router.push({path: "/affiliates/list"});
      }).catch(() => {
        this.haveError = true
        this.haveSuccess = false
        this.successMessage = ''
        this.errorMessage = this.$store.state.affiliates.errorMessage
      })
    },
    getData(){
      this.$store.dispatch('affiliates/detail', this.$route.params.id).then(() =>{
        this.edit_affiliate.id = this.$store.state.affiliates.data.data.id
        this.edit_affiliate.name = this.$store.state.affiliates.data.data.name
        this.edit_affiliate.username = this.$store.state.affiliates.data.data.username
        this.edit_affiliate.surname = this.$store.state.affiliates.data.data.surname
        this.edit_affiliate.percent = this.$store.state.affiliates.data.data.percent
      }).catch(() => {
        // 404 page redirect
      })
    }
  },
  mounted() {
    this.getData()
  }
};
</script>

