import Axios from 'axios'

export const state = {
    errorMessage:'',
    successMessage:'',
    data:[],
}
export const mutations = {
    error_data(state,data){
        state.errorMessage = data
    },
    success_data(state,data){
        state.successMessage = data
    },
    response_data(state,data){
        state.data = data
    }
}

export const getters = {

}

export const actions = {
    list({ commit }, querystring){
        return new Promise((resolve, reject) => {
            Axios({url: process.env.VUE_APP_API_ENDPOINT+'/dashboard?'+querystring, method:"GET"}).then(resp => {
                commit('response_data',resp.data)
                resolve(resp)
            }).catch(err => {
                for(let i in err.response.data){
                    commit('error_data',err.response.data[i][0])
                }
                reject(err)
            })
        })
    },

}