<template>
  <div class="col-xl-6">
    <div class="card">
      <div class="card-body">
        <div class="float-end">
          <b-dropdown right toggle-class="p-0" variant="white" menu-class="dropdown-menu-end">
            <template v-slot:button-content>
              <span class="text-muted">
                {{$t('dashboard.third_line.date.text')}}
                <i class="mdi mdi-chevron-down ms-1"></i>
              </span>
            </template>
            <b-dropdown-item href="#">{{$t('dashboard.third_line.date.text')}}</b-dropdown-item>
            <b-dropdown-item href="#">{{$t('dashboard.third_line.date.text2')}}</b-dropdown-item>
          </b-dropdown>
        </div>
        <h4 class="card-title mb-4">{{$t('dashboard.third_line.title.text2')}}</h4>
        <div data-simplebar style="max-height: 336px;">
          <div class="table-responsive">
            <ol class="activity-feed mb-0 ps-2"  >
              <li class="feed-item" v-for="(k,index) in activities" :class="activities.length-1===index ? 'pb-1' : ''">
                <div class="feed-item-list">
                  <p class="text-muted mb-1 font-size-13">
                    {{$t('dashboard.third_line.data_activity.text')}}
                    <small class="d-inline-block ms-1">{{ k.created_at }}</small>
                  </p>
                  <p class="mt-0 mb-0">
                    {{$t('alerts.'+k.name)}}
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['activities'],
};
</script>